import React from "react";
import {
  Grid,
  Box,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { Link } from "react-router-dom";

import DrawerComponent from "./Drawer";

const useStyles = makeStyles((theme) => ({
  logo: {
    color: "#187CA7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",

    color: "#187CA7",
    fontSize: "20px",
    fontWeight: "500",
    marginLeft: theme.spacing(10),
    "&:hover": {
      color: "#000",
      borderBottom: "1px black",
    },
  },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box p={4} display="flex" justifyContent="center">
        <Grid
          container
          item
          lg={10}
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            className={classes.logo}
            onClick={(event) => (window.location.href = "/")}
          >
            <Typography variant="h4">Internabad</Typography>
          </Box>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <Grid container item lg={6} justifyContent="flex-end">
              <Link to="/" className={classes.link}>
                Home
              </Link>
              <Link to="/internships" className={classes.link}>
                Internships
              </Link>
              <Link to="/jobs" className={classes.link}>
                Jobs
              </Link>
              <Link to="/blogs" className={classes.link}>
                Blogs
              </Link>
            </Grid>
          )}
        </Grid>
      </Box>

      <hr />
    </>
  );
}
export default Navbar;
