import React, { useState, useEffect } from "react";
import BlogCard from "./BlogCard";

import {
  Typography,
  makeStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `url('https://images.unsplash.com/photo-1588345921523-c2dcdb7f1dcd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80')`,
    height: "350px",
    maxWidth: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignItems: "center",
    justifyContent: "center",
    direction: "column",

    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      height: 200,
    },
  },

  headingText: {
    fontSize: "36px",
    fontFamily: "Roboto",

    color: "#4d4a4a",
    fontWeight: 1000,
  },
  normalText: {
    fontSize: "20px",
    fontFamily: "Roboto",

    color: "#4d4a4a",
    fontWeight: 400,
    textDecoration: "none",
  },

  paginationContainer: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    margin: "30px",
  },
}));
function BlogList() {
  const classes = useStyles();

  const mediumRssFeed =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@internabad";

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await fetch(mediumRssFeed);
    const data = await response.json();
    setPosts(data.items);
  };

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  return (
    <>
      <Grid container className={classes.hero}>
        <Typography className={classes.headingText}>Blog</Typography>
      </Grid>
      {loading ? (
        <CircularProgress color="secondary" size={22} />
      ) : (
        <Grid container justifyContent="center">
          <Grid
            container
            item
            xs={12}
            lg={10}
            spacing={10}
            justifyContent="center"
          >
            {posts.map((post, index) => (
              <Grid item lg={5}>
                <BlogCard key={index} post={post} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default BlogList;
