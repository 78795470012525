import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header/index.js";
import Footer from "./components/Footer/Footer.js";
import Blogs from "./pages/Blog.js";
import BlogPage from "./components/Blog/BlogPage.js";
import { HelmetProvider } from "react-helmet-async";

const LandingPage = lazy(() => import("./pages/LandingPage"));

const Jobs = lazy(() => import("./pages/Jobs"));
const Internships = lazy(() => import("./pages/Internships"));

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <HelmetProvider>
      <Suspense fallback={load}>
        <Router>
          <div id={load ? "preloader" : "preloader-none"} />
          <div className="App" id={load ? "no-scroll" : "scroll"}>
            {<Header />}
            <Routes>
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/internships" element={<Internships />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/:blogId" element={<BlogPage />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
