import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import {
  FacebookShareButton as FacebookIcon,
  LinkedinShareButton as LinkedinIcon,
  TwitterShareButton as TwitterIcon,
} from "react-share";
import {
  AiFillLinkedin,
  AiFillTwitterSquare,
  AiFillFacebook,
} from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80')`,
    height: "350px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    maxWidth: "100%",
    alignItems: "center",
    display: "grid",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "3em",
    },
  },
  heroText: {
    fontFamily: "Roboto",
    textAlign: "center",
    color: "#fff",
    fontSize: "20px",
  },

  heroHeading: {
    fontSize: "36px",
    fontFamily: "Roboto",

    textAlign: "center",
    color: "#fff",

    fontWeight: 800,
  },
  content: {
    marginTop: "50px",
    fontSize: "18px",
    textAlign: "justify",
    fontFamily: "Roboto",
    color: "#4d4a4a",
    "& img": {
      width: "100%",
    },
  },
}));
const BlogPage = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState([]);
  const mediumRssFeed =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@internabad";

  const fetchBlog = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(mediumRssFeed);
      const data = await response.json();
      resolve(data.items);
    });
  };

  useEffect(() => {
    fetchBlog().then((blogs) => {
      const location = window.location.href.split("/").pop();
      //console.log(location);
      const fetchedBlog = {};
      blogs.forEach((blog, i) => {
        if (location === blog.link.slice(30).split("?")[0]) {
          fetchedBlog.title = blog.title ? blog.title : "No title given";
          fetchedBlog.date = blog.pubDate ? blog.pubDate : "No date given";
          fetchedBlog.author = blog.author ? blog.author : "No author given";
          fetchedBlog.link = blog.link ? blog.link : "No link";
          fetchedBlog.content = blog.content
            ? blog.content
            : "No content given";
          setBlog(fetchedBlog);
          console.log(fetchedBlog.link.slice(30).split("?")[0]);
          setLoading(false);
        }
      });
    });
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress color="secondary" size={22} />
      ) : (
        <>
          <Grid container className={classes.hero}>
            <Typography className={classes.heroText}>
              {blog.category}
            </Typography>
            <Typography className={classes.heroHeading}>
              {blog.title}
            </Typography>
            <Typography className={classes.heroText}>
              by {blog.author}
            </Typography>
          </Grid>
          <Grid container item justifyContent="center">
            <Grid
              item
              xs={10}
              lg={8}
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: blog.content,
              }}
            ></Grid>
            <Grid item xs={8}>
              <Typography className={classes.normalText}>share : </Typography>
              <LinkedinIcon
                className={classes.shareicon}
                url={`https://www.internabad.com/blogs/${
                  blog.link.slice(30).split("?")[0]
                }`}
                title={`Check out this blog I found about \n ${blog.title}`}
                image={blog.titleImage}
              >
                <AiFillLinkedin style={{ opacity: "0.5" }} size={24} />
              </LinkedinIcon>

              <TwitterIcon
                className={classes.shareicon}
                url={`https://www.internabad.com/blogs/${
                  blog.link.slice(30).split("?")[0]
                }`}
                title={`Check out this blog I found about \n ${blog.title}`}
                image={blog.titleImage}
              >
                <AiFillTwitterSquare style={{ opacity: "0.5" }} size={24} />
              </TwitterIcon>

              <FacebookIcon
                className={classes.shareicon}
                url={`https://www.internabad.com/blogs/${
                  blog.link.slice(30).split("?")[0]
                }`}
                title={`Check out this blog I found about \n ${blog.title}`}
                image={blog.titleImage}
              >
                <AiFillFacebook style={{ opacity: "0.5" }} size={24} />
              </FacebookIcon>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BlogPage;
