import React from "react";
import BlogList from "../components/Blog/BlogList";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Internabad - Blogs</title>

        <meta itemprop="name" content="Internabad" />
        <meta itemprop="description" content="Internabad - Blogs" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/internabad-79d69.appspot.com/o/Frame%201.jpg?alt=media&token=62c5fa54-30bb-4c2b-aa75-51f15c2b3850"
        />
        <meta property="og:title" content="Internabad - Blogs" />
        <meta property="og:type" content="blog" />
        <meta property="og:description" content="Internabad - Blogs" />
        <meta property="og:site_name" content="Internabad" />
        <meta property="og:url" content="https://www.internabad.com/blogs" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content="Internabad image" />

        <meta name="twitter:site" content="@internabad" />
      </Helmet>
      <BlogList />
    </>
  );
};

export default Blog;
