import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Icon } from "@material-ui/core";

import { Link } from "react-router-dom";
import { ArrowRightAltOutlined as ReadMoreIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  blogsContainer: {
    paddingTop: theme.spacing(3),
  },

  card: {
    maxWidth: "100%",
    marginTop: "10px",

    marginBottom: "10px",
  },
  media: {
    maxWidth: "100%",
    borderRadius: "10px",
    maxHeight: "400px",
  },
  title: {
    fontSize: "30px",
    lineHeight: "40px",
    fontFamily: "roboto",
    fontWeight: 550,
    color: "#363333",
    marginTop: "10px",

    textDecoration: "none",
    "&:hover": {
      color: "#187CA7",
    },
  },
  excerpt: {
    fontSize: "20px",
    fontFamily: "Roboto",
    fontWeight: 400,
    color: "#4d4a4a",
    marginTop: "30px",
    textAlign: "justify",
    marginBottom: "30px",
  },
  read: {
    fontSize: "18px",
    fontFamily: "Roboto",
    fontWeight: 400,
    color: "#4d4a4a",

    textDecoration: "none",
    "&:hover": {
      color: "#187CA7",
      textDecoration: "underline",
    },
  },
  date: {
    fontSize: "18px",
    fontFamily: "Roboto",
    fontWeight: 400,
    color: "#4d4a4a",
  },
  icon: {
    marginTop: "100px",
  },
}));
const BlogCard = (props) => {
  const classes = useStyles();

  const parseDate = (date) => {
    const year = date?.substring(0, 4);
    const month = date?.substring(5, 7);
    const day = date?.substring(8, 10);
    switch (month) {
      case "01":
        return day + " January " + year;
      case "02":
        return day + " February " + year;
      case "03":
        return day + " March " + year;
      case "04":
        return day + " April " + year;
      case "05":
        return day + " May " + year;
      case "06":
        return day + " June " + year;
      case "07":
        return day + " July " + year;
      case "08":
        return day + " August " + year;
      case "09":
        return day + " September " + year;
      case "10":
        return day + " October " + year;
      case "11":
        return day + " November " + year;
      case "12":
        return day + " December " + year;
      default:
        return "No publication date";
    }
  };

  console.log();
  return (
    <Grid container>
      <Grid container item className={classes.card}>
        <img
          className={classes.media}
          src={props.post.thumbnail}
          alt="BlogCard"
        />
        <Link
          className={classes.title}
          to={`/blogs/${props.post.link.slice(30).split("?")[0]}`}
        >
          {props.post.title}
        </Link>
        <Grid
          container
          item
          justifyContent="space-between"
          style={{ marginTop: "20px" }}
        >
          <Grid item>
            <Typography className={classes.date}>
              {parseDate(props.post.pubDate)}
            </Typography>
          </Grid>

          <Link
            className={classes.read}
            to={props.post.link.slice(30).split("?")[0]}
          >
            <Grid container item>
              <Typography>READ MORE </Typography>
              <Icon>
                <ReadMoreIcon />
              </Icon>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default BlogCard;
