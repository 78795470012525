import {
  Typography,
  Box,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedinIcon,
} from "@material-ui/icons";
import logoicon from "../Header/ico.ico";

import React from "react";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "1px solid #e8e8e8",
    cursor: "pointer",
    transition: "0.3s",
    display: "flex",
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <Box justifyContent="center" className={classes.wrapper} mt={10} p={5}>
      <Grid container item xs={10} justifyContent="center" alignItems="center">
        <Grid item container alignItems="center" xs={10} lg={3}>
          <img
            style={{ width: "30px", height: "30px" }}
            href="/"
            src={logoicon}
            alt=" "
          ></img>
          <Typography variant="h4">Internabad</Typography>
        </Grid>

        <Grid item xs={10} lg={4}>
          <Typography>
            Copyright © 2023 Internabad. All rights reserved.
          </Typography>
        </Grid>

        <Grid container item xs={10} lg={3} alignItems="flex-end">
          <IconButton
            onClick={(event) =>
              window.open(
                "https://www.facebook.com/profile.php?id=100086915705294"
              )
            }
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            onClick={(event) =>
              window.open("https://www.instagram.com/internabad/?hl=en")
            }
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            onClick={(event) =>
              window.open("https://www.twitter.com/internabad/")
            }
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            onClick={(event) =>
              window.open("https://www.linkedin.com/company/internabad/")
            }
          >
            <LinkedinIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
